import { Product } from "../models";

export interface Wishlist {
    name: string;
    isPublic: boolean;
    uuid?: string;
    chosen?: boolean;
    productsRef: { uuid: string }[];
    products?: Product[];
    needToSync?: boolean;
  }