export interface EnvironmentConfiguration {
  acceptAllCertificates: boolean;
  organizationUUID: string;
  organizationPrefix: string;
  authenticationUrl: string;
  usersUrl: string;
  organizationUrl: string;
  productsUrl: string;
  structuresUrl: string;
  searchToRelated: boolean;
  generalFeedbackUrl: string;
  configUrl:string;
  changelogUrl: string;
  wishlistUrl: string;
  userStructureUrl: string;
  pageLimit: number;
  highlightLimit: number;
  featureForCommunicationsUrl: string;
  mainPageStructureTabsUUIDs: string[];
  featureSectionsMap: any[];
  systemUserToken: string;
  cataleanCacheUrl: string;
  accountId: string;
  defaultLocale: string;
  version: string;
  templateUrl: string;
  mappingUUID: string;
  uuidParameterType: string,
  availableLocales: string[];
  wishlistExportProductFields?: string[];
  useVirtualKeyboard?: boolean
  catalean: {
    uuid: string;
    version: number;
  };
  collectionsUrl?: string
  userStructureUUID?: string
}
