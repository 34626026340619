import {StructureFieldType} from "../models/StructureTypes.enum";

export interface BaseStructureField {
  uuid: string;
  parentUUID: string;
  name: string;
  type: StructureFieldType;
  repeatable: boolean; // Flag che indica se questo campo è ripetibile all'interno di un prodotto della struttura a cui appartiene questo campo.
  label: string;  // Hashmap di valori che indicano le label, localizzate per lingua, di questo campo all'interno delle form di prodotto di questa struttura. L'hashmap è composto dalla coppia "IETF language tag" : "valore".
  orderId: number;
  fields?: BaseStructureField[];
  value?: string;
  extra?: string;
  isRequired?: boolean;
  viewInApp?: boolean;
}

export interface BaseStructure {
  uuid: string;
  name: string;
  description: string;
  structureFields: BaseStructureField[];
}

export interface Structure extends BaseStructure {
  type: string;
  mobileRatio?: number;
  tabletRatio?: number;
  desktopRatio?: number;
}
