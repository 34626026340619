export interface Filter {
  hidden?: boolean;
  name: string;
  widgetType?: string;
  children?: Filter[];
  align: 'metro' | 'horizontal' | 'vertical';
  enabled?: boolean;
  collapsible?: boolean;
  collapsed?: boolean;
  showInSelectedFiltersArea?: boolean;
  label: string;
  iconURL: string;
  order?: number;
  operator?: string;
  defaultValue?: string;
  entityType?: string | string[];
  featureId: string;
  isRoot?: boolean;
  imageURL?: {
    url: string;
    name?: string;
    altText?: string;
    previewURL?: string;
  }
}

export interface MetroFilter {
  level: number;
  name: string;
  filter: Filter;
}

export interface ActiveFilter {
  parentFilter: Filter;
  values: Filter[];
} 