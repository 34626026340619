
export interface CoreEntity {
  name: string;
  related: string[];
  uuid: string;
  features:  string[] ;
  structureUUID: string;
  status: string;
  order: number;
  //[structureFieldName: string]: any;
}
